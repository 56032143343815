@font-face {
  font-family: 'Red Hat Display';
  src: url('../fonts/static/RedHatDisplay-Medium.ttf') format('truetype');
}

.container {
  position: relative;
}

.image {
  width: 100%;
  height: auto;
}

.overlay {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start; /* Alinea los elementos a la izquierda */
  color: white;
  padding: 20px; /* Añade un relleno general */
}

.diagonalCross2 {
  background: linear-gradient(
    to top left,
    #132a55 calc(50% - 1px),
    black,
    #fff calc(50% + 1px)
  );
}

.fondotabla {
  background-image: url('../asset/images/fondotabla.png');
}

.formContainer {
  margin-left: 200px;
  padding: 20px;
  border-radius: 10px;
  margin-bottom: 20px;
}

.overlayText {
  font-size: 18px;
  font-weight: bold;
}

.divConImagen {
  background-image: url('../asset/images/img51.jpeg');
  background-size: cover;
  width: 100%;
  height: 400px;
}

.contenedor-imagen {
  position: relative;
}

.fondo {
  background-image: url('../asset/images/img51.jpeg');
  background-size: cover;
  width: 100%;
  overflow: auto;
}

.overlay-imagen {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(4, 20, 52, 0.8);
  display: flex;
  align-items: center;
  justify-content: baseline;
  flex-direction: column;
}

.contenedor-main {
  position: relative;
}

.contenedor-main-carrousel {
  position: relative;
  height: 650px;
}

.fondo-main {
  position: relative;
  background: url('../asset/images/img11.jpeg') no-repeat;
  background-position: center center;
  background-size: cover;
  width: 100%;
  height: 430px;
  min-height: 650px;
}

.overlay-main {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: flex-start;
  justify-content: baseline;
  flex-direction: column;
}

.font-title-main {
  font-family: 'Red Hat Display', sans-serif;
  font-size: 38px;
  margin-left: 50px;
  width: 40%;
}

.font-title-main2 {
  font-size: 23px;
  margin-left: 50px;
  width: 40%;
  margin-top: -20px;
}

.font-title-main3 {
  font-size: 19px;
  margin-left: 50px;
  width: 60%;
  margin-top: 20px;
}

.input-main {
  width: 35%;
}

.font-title-submain {
  font-size: 38px;
}

.font-title-submain2 {
  font-size: 23px;
}

.contenedor-automovil {
  position: relative;
}

.fondo-automovil {
  position: relative;
  background: url('../asset/images/medico_automovil.jpg') no-repeat fixed;
  background-position: center center;
  background-size: cover;
  width: 100%;
  height: 100%;
  min-height: 500px;
}

.overlay-automovil {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: flex-start;
  justify-content: baseline;
  flex-direction: column;
}

.font-title-automovil {
  font-size: 20px;
  margin-left: 50px;
  width: 90%;
}

.font-title-automovil2 {
  font-size: 30px;
  margin-left: 50px;
  width: 50%;
  margin-top: -20px;
}

.font-title-automovil3 {
  font-size: 20px;
  margin-left: 80px;
  width: 40%;
  margin-top: -20px;
}

.fondo-impulsar {
  position: relative;
  background: url('../asset/images/bg-call-action.jpg') no-repeat fixed;
  background-position: center center;
  background-size: cover;
  width: 100%;
  height: 100%;
  min-height: 600px;
}

.overlay-impulsar {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.div-img-arrendamiento-equipo {
  width: 700px;
}

.div-img-arrendamiento-font {
  font-size: 26px;
  margin-top: 70px;
}

.div-img-arrendamiento-button {
  height: 50px;
  width: 230px;
  font-size: 18px;
}

.img-arrendamiento {
  height: 300px;
}

.fondo-main-diferenciadores {
  position: relative;
  background: url('../asset/images/bg-beneficios.jpg') no-repeat fixed;
  background-position: center center;
  background-size: cover;
  width: 100%;
  height: 430px;
  min-height: 650px;
}

.overlay-diferenciadores {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: flex-start;
  justify-content: baseline;
  flex-direction: column;
}

.div-diferenciadores {
  margin-left: 80px;
}

.div-title-diferenciadores {
  font-size: 38px;
  width: 100%;
}

.div-title-diferenciadores2 {
  font-family: 'Red Hat Display', sans-serif;
  font-size: 19px;
  width: 50%;
  margin-top: -20px;
}

.div-title-diferenciadores3 {
  font-size: 30px;
}

.div-title-beneficios {
  font-size: 38px;
  width: 90%;
}

.div-title-beneficios2 {
  font-size: 23px;
  width: 60%;
  margin-top: -20px;
}

.check-icon-circle-automovil {
  width: 17px;
  height: 17px;
  margin-top: 0px;
}

.check-icon-circle {
  width: 27px;
  height: 27px;
  margin-top: 5px;
}

.check-icon-circle {
  font-size: 17px;
}

.div-title-diferenciadores4 {
  font-size: 16px;
  font-family: 'Red Hat Display', sans-serif;
}

.div-title-conoce {
  font-size: 38px;
}

.div-subtitle-conoce {
  font-size: 25px;
}

.collapse-conoce {
  width: 70%;
}

.logo-footer {
  width: 250px;
}

.div-footer-adress {
  font-size: 17px;
}

.footer-link {
  color: white;
  font-size: 18px;
}

.div-titulo-beneficios {
  font-size: 38px;
}

.div-titulo-beneficios2 {
  font-size: 22px;
  margin-top: -25px;
}

.div-titulo-beneficios3 {
  font-size: 17px;
  font-weight: bold;
  margin-top: 30px;
}

.div-titulo-beneficios4 {
  font-size: 15px;
  margin-top: 10px;
}

.div-titulo-candidato {
  font-size: 30px;
}

.div-titulo-candidato2 {
  font-size: 18px;
}

.div-card-requisitos {
  text-align: center;
  background-color: white;
  border-radius: 25px;
  padding: 10px;
  box-shadow: rgba(0, 0, 0, 0.75) 0px 0px 22px -7px;
  max-width: 250px;
  padding-top: 20px;
}

.div-card-requisitos-text {
  font-size: 14px;
  font-weight: bold;
  margin-top: 0px;
}

.div-card-requisitos-imagen {
  width: 600px;
  margin-left: -150px;
}

.div-collapse-preguntas {
  margin-left: 50px;
  margin-right: 50px;
  font-size: 17px;
  text-align: justify;
}

.div-collapse-table {
  border: 1px solid rgba(77, 73, 190, 0.5);
}

.div-cards-candidato {
  margin-left: 100px;
  margin-right: -150px;
  max-width: 67%;
}

.modal-pld {
  top: 200px;
}

.table-title-1 {
  font-size: 15px;
}

.div-col-enlaces {
  margin-left: 20px;
}

@media screen and (max-width: 1800px) {
  .contenedor-main-carrousel {
    position: relative;
    height: 600px;
  }
}

@media screen and (max-width: 1400px) {
  .div-cards-candidato {
    margin-left: 20px;
  }
}

@media screen and (max-width: 1200px) {
  .div-card-requisitos-imagen {
    width: 500px;
    margin-left: 0px;
  }
}

@media screen and (max-width: 1080px) {
  /* .div-global-cards{
    margin-left: 60px;
  } */

  .div-titulo-candidato {
    font-size: 30px;
    width: 100%;
  }

  .div-titulo-candidato2 {
    font-size: 18px;
    width: 100%;
  }

  .formContainer {
    margin-left: 60px;
  }

  .font-title-main {
    font-size: 25px;
  }

  .font-title-submain {
    font-size: 25px;
  }

  .font-title-main2 {
    font-size: 20px;
    margin-left: 50px;
  }

  .font-title-submain2 {
    font-size: 20px;
  }

  .font-title-main3 {
    font-size: 15px;
    margin-left: 50px;
  }

  .div-img-arrendamiento-equipo {
    width: 700px;
  }

  .div-img-arrendamiento-font {
    font-size: 17px;
    margin-left: 55px;
    text-align: center;
    margin-top: 70px;
  }

  .div-img-arrendamiento-button {
    height: 50px;
    width: 170px;
    margin-left: 40px;
    font-size: 12px;
  }

  .div-card-requisitos-imagen {
    width: 500px;
    margin-left: -40px;
  }

  .div-card-requisitos {
    width: 200px;
  }

  .fondo {
    min-height: 600px;
  }
}

@media screen and (max-width: 950px) {
  .div-card-requisitos {
    width: 180px;
  }
}

@media screen and (max-width: 1000px) {
  .div-card-requisitos-imagen {
    width: 380px;
    margin-left: 10px;
  }
}

@media screen and (max-width: 900px) {
  .div-card-requisitos {
    width: 150px;
  }

  .div-card-requisitos-text {
    font-size: 11px;
  }

  .div-cards-candidato {
    margin-left: 0px;
  }

  .font-title-automovil2 {
    font-size: 30px;
    width: 85%;
  }

  .div-img-arrendamiento-equipo {
    width: 550px;
  }

  .table-title-1 {
    font-size: 11px;
  }

  .div-col-enlaces {
    margin-left: 20px;
  }
}

@media screen and (max-width: 900px) {
  /* .div-global-cards{
    margin-left: 50px;
  }  */
}

@media screen and (max-width: 750px) {
  /* .div-global-cards{
    margin-left: 70px;
  }  */

  .div-card-requisitos-imagen {
    display: none;
  }

  .div-titulo-candidato {
    width: 100%;
  }

  .div-titulo-candidato2 {
    width: 100%;
  }

  .div-cards-candidato {
    justify-content: center;
    max-width: 100%;
    margin-left: 0px;
    margin-right: 0px;
  }

  .div-card-requisitos {
    width: 200px;
  }

  .fondo-main {
    background-position: 60%;
  }
}

@media screen and (max-width: 700px) {
  .div-diferenciadores {
    margin-left: 30px;
  }
  .div-card-requisitos-imagen {
    width: 290px;
  }

  .div-card-requisitos-text {
    font-size: 9px;
  }

  .font-title-automovil {
    font-size: 20px;
    margin-bottom: 20px;
  }

  .font-title-automovil2 {
    font-size: 30px;
  }

  .font-title-automovil3 {
    width: 350px;
    font-size: 17px;
    margin-left: 50px;
  }
}

@media screen and (max-width: 610px) {
  .fondo {
    min-height: 680px;
  }
}

@media screen and (max-width: 600px) {
  /* .div-global-cards{
    margin-left: 70px;
  }  */

  .div-diferenciadores {
    margin-left: 15px;
  }

  .font-title-automovil {
    font-size: 20px;
    margin-bottom: 20px;
    margin-left: 25px;
    width: 450px;
  }

  .font-title-automovil2 {
    font-size: 30px;
    width: 450px;
    margin-left: 25px;
  }

  .font-title-automovil3 {
    width: 350px;
    font-size: 17px;
    margin-left: 25px;
  }

  .div-titulo-beneficios {
    font-size: 30px;
  }

  .div-titulo-beneficios2 {
    font-size: 17px;
  }

  .div-titulo-beneficios3 {
    font-size: 14px;
  }

  .div-titulo-beneficios4 {
    font-size: 13px;
  }

  .formContainer {
    margin-left: 10px;
  }

  .font-title-main {
    font-size: 20px;
    width: 80%;
    margin-top: 30px;
  }

  .font-title-submain {
    font-size: 20px;
  }

  .font-title-main2 {
    font-size: 16px;
    margin-left: 50px;
    width: 70%;
    margin-top: -10px;
  }

  .font-title-submain2 {
    font-size: 16px;
  }

  .font-title-main3 {
    font-size: 12px;
    margin-left: 50px;
  }

  .input-main {
    width: 70%;
  }

  .div-title-diferenciadores {
    font-size: 28px;
    width: 95%;
  }

  .div-title-diferenciadores2 {
    font-size: 16px;
    width: 90%;
    margin-top: -10px;
  }

  .div-title-diferenciadores3 {
    font-size: 20px;
  }

  .check-icon-circle {
    width: 21px;
    height: 21px;
    margin-top: 0px;
  }

  .check-icon-circle {
    font-size: 12px;
  }

  .div-title-diferenciadores4 {
    font-size: 16px;
  }

  .fondo-main {
    height: 300px;
  }

  .div-title-conoce {
    font-size: 21px;
  }

  .div-subtitle-conoce {
    font-size: 16px;
  }

  .collapse-conoce {
    width: 90%;
    background-color: rgb(252, 253, 253);
    margin-top: 28px;
    border-radius: 15px;
    box-shadow: rgba(0, 0, 0, 0.35) 0px -1px 10px 0px;
  }

  .logo-footer {
    width: 150px;
  }

  .div-footer-adress {
    font-size: 15px;
    margin-top: 10px;
  }

  .footer-link {
    font-size: 14px;
  }

  .div-titulo-candidato {
    font-size: 21px;
    width: 100%;
  }

  .div-titulo-candidato2 {
    font-size: 16px;
    width: 100%;
  }

  .div-cards-candidato {
    margin-left: 0px;
  }

  .div-img-arrendamiento-equipo {
    width: 450px;
  }

  .div-img-arrendamiento-button {
    width: 120px;
    margin-left: 70px;
    font-size: 11px;
  }

  .div-card-requisitos {
    max-width: 170px;
  }
}

@media screen and (max-width: 530px) {
  /* .div-global-cards{
    margin-left: 50px;
  }  */

  .div-img-arrendamiento-equipo {
    width: 370px;
  }

  .div-img-arrendamiento-font {
    font-size: 13px;
    margin-left: 30px;
    text-align: center;
    margin-top: 40px;
  }

  .img-arrendamiento {
    height: 200px;
  }

  .div-img-arrendamiento-button {
    width: 145px;
    margin-left: 13px;
    font-size: 11px;
  }

  .font-title-automovil {
    font-size: 17px;
    margin-bottom: 20px;
    margin-left: 25px;
    width: 350px;
  }

  .font-title-automovil2 {
    font-size: 25px;
    width: 350px;
    margin-left: 25px;
  }

  .font-title-automovil3 {
    width: 350px;
    font-size: 15px;
    margin-left: 25px;
  }

  .div-card-requisitos {
    max-width: 150px;
  }
}

@media screen and (max-width: 450px) {
  .div-card-requisitos {
    max-width: 130px;
  }
}

@media screen and (max-width: 400px) {
  .div-title-diferenciadores4 {
    font-size: 14px;
  }

  .div-card-requisitos {
    max-width: 130px;
  }

  /* .div-global-cards{
    margin-left: 20px;  
  }  */

  .div-titulo-candidato {
    margin-left: -20px;
  }

  .div-titulo-candidato2 {
    margin-top: 1px;
    margin-left: -20px;
  }
}
